import React, { useEffect, useState, useContext } from "react";
import { auth } from "../utils/firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

const FirebaseAuthContext = React.createContext();

export function useAuth() {
    return useContext(FirebaseAuthContext);

}
export function FirebaseAuthProvider({ children }) {
    const [firebaseUser, setFirebaseUser] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe;
    }, [])

    async function initializeUser(user) { 
        if (user) {
            setFirebaseUser({ ...user});
            setUserLoggedIn(true);
        } else {
            setFirebaseUser(null);
            setUserLoggedIn(false);
        }
        setLoading(false);
    }

    const value = {
        firebaseUser: firebaseUser,
        userLoggedIn,
        loading
    }

    return (
        <FirebaseAuthContext.Provider value={value}>
            {!loading && children}
        </FirebaseAuthContext.Provider>
    )
}