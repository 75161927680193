import React, { useState, useEffect } from 'react';

const CountdownPage = () => {
      // Set the target date for November 16, 2024 at 6:00 PM MST (UTC-7)
  const targetDate = new Date('2024-11-16T18:00:00-0700').getTime(); // MST is UTC-7

  const [timeLeft, setTimeLeft] = useState(targetDate - new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(targetDate - new Date().getTime());
    }, 1000);

    // Clear interval when component is unmounted
    return () => clearInterval(timer);
  }, [targetDate]);

  // Function to format the remaining time into days, hours, minutes, and seconds
  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24)); // calculate days
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // calculate hours
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)); // calculate minutes
    const seconds = Math.floor((time % (1000 * 60)) / 1000); // calculate seconds
    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(timeLeft);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundImage: 'url("/assets/images/countdown.jpg")', 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: 'white',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        {/* "Memberships live in:" Label */}
        <div style={{ fontSize: '2rem', marginBottom: '20px' }}>
          Memberships live in:
        </div>

        {/* Countdown Numbers and Labels */}
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
          {/* Days Section */}
          <div>
            <div style={{ fontSize: '3rem' }}>{days}</div>
            <div style={{ fontSize: '1.5rem' }}>Days</div>
          </div>

          {/* Hours Section */}
          <div>
            <div style={{ fontSize: '3rem' }}>
              {hours < 10 ? '0' + hours : hours}
            </div>
            <div style={{ fontSize: '1.5rem' }}>Hours</div>
          </div>

          {/* Minutes Section */}
          <div>
            <div style={{ fontSize: '3rem' }}>
              {minutes < 10 ? '0' + minutes : minutes}
            </div>
            <div style={{ fontSize: '1.5rem' }}>Minutes</div>
          </div>

          {/* Seconds Section */}
          <div>
            <div style={{ fontSize: '3rem' }}>
              {seconds < 10 ? '0' + seconds : seconds}
            </div>
            <div style={{ fontSize: '1.5rem' }}>Seconds</div>
          </div>
        </div>

        {/* If time is up */}
        {timeLeft <= 0 && (
          <div style={{ fontSize: '2rem', marginTop: '20px' }}>Now</div>
        )}
      </div>
    </div>
  );
};

export default CountdownPage;