import * as Yup from 'yup';

// Helper function to calculate age
const calculateAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, "First Name must be at least 2 characters")
        .max(50, "First Name must be at most 50 characters")
        .required('First Name is required'),
    lastName: Yup.string()
        .min(2, "Last Name must be at least 2 characters")
        .max(50, "Last Name must be at most 50 characters")
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters'),
    dateOfBirth: Yup.date()
        .required('Date of Birth is required')
        .nullable(),
    guardianFirstName: Yup.string()
        .when('dateOfBirth', (dateOfBirth, schema) => {
            return dateOfBirth && calculateAge(dateOfBirth) < 18
                ? schema.required("Guardian's First Name is required")
                : schema;
        }),
    guardianLastName: Yup.string()
        .when('dateOfBirth', (dateOfBirth, schema) => {
            return dateOfBirth && calculateAge(dateOfBirth) < 18
                ? schema.required("Guardian's Last Name is required")
                : schema;
        }),
    guardianEmail: Yup.string()
        .when('dateOfBirth', (dateOfBirth, schema) => {
            return dateOfBirth && calculateAge(dateOfBirth) < 18
                ? schema.email('Invalid email').required("Guardian's Email is required")
                : schema;
        }),
    agreement: Yup.boolean()
        .oneOf([true], 'You must accept the Terms and Conditions and Privacy Policy'),
});
