import React, { useEffect, useRef } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export default function CompetitorPersonalityChart({ userAnswers, populationData }) {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            // This triggers re-rendering by updating the key
            chartRef.current.update();
        }
    }, [userAnswers]);

    const dataForChart = {
        labels: ['Personal Fulfillment', 'Love of the Game', ['Community &', 'Camaraderie'], 'Practical Application', ['Achieving', 'Greatness']],
        datasets: [
            {
                label: 'You',
                data: userAnswers,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                pointBackgroundColor: 'rgba(54, 162, 235, 1)',
            },
            {
                label: 'Average',
                data: [6, 7, 8, 5, 4],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                pointBackgroundColor: 'rgba(255, 99, 132, 1)',
            },
        ],
    };

    const options = {
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
        },
        scale: {
            min: 1,
            max: 10
        },
    };

    return (
        <div>
            <Radar
                key={userAnswers.join(',')} // Use userAnswers as a key to force re-render
                ref={chartRef}
                data={dataForChart}
                options={options}
            />
        </div>
    );
}
