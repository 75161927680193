import { useState } from "react";
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { doPasswordReset } from "../../utils/firebase/auth";
import ErrorAlert from "../common/ErrorAlert";
import SuccessAlert from "../common/SuccessAlert";

const ForgotPasswordValidation = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
        .required('Email is required'),
});

export default function ForgotPassword() {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    return (
        <div className="mx-auto w-full max-w-sm lg:w-96">
            <h1 className="text-3xl font-semibold">Forgot password</h1>
            <p className="mt-4 text-center">Enter your email address and we'll send you a link to reset your password.</p>
            <div className="mt-4">
                {errorMessage && !successMessage && <ErrorAlert message={errorMessage} />}
                {successMessage && !errorMessage && <SuccessAlert message={successMessage} />}
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={ForgotPasswordValidation}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setSubmitting(true);
                            doPasswordReset(values.email);
                            if (errorMessage) setErrorMessage('');
                            setSuccessMessage('If an account with this email exists, a password reset email will be sent');
                        } catch (error) {
                            if(successMessage) setSuccessMessage('');
                            setErrorMessage(error.message || 'An error occurred')
                        } finally {
                            setSubmitting(false);
                        }
                    }}                  
                >
                    {({ values, errors, touched, isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Field
                                    className="formik-input"
                                    type="email"
                                    id="email"
                                    name="email"
                                />
                                {touched.email && errors.email && <div className="text-red-700">{errors.email}</div>}
                            </div>
                            <div className="mt-3">
                                <button type="submit" class="w-full text-center btn-primary" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Send Password Reset Email'}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}