import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ErrorAlert from "../common/ErrorAlert";
import SuccessAlert from "../common/SuccessAlert";
import { doConfirmPasswordReset } from "../../utils/firebase/auth";

const ResetPasswordValidation = Yup.object().shape({
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmedPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required')
});

export default function ResetPassword() {
    const [searchParams, setSearchParams] = useSearchParams();
    const oobCode = searchParams.get('oobCode');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    return (
        <div className="mx-auto w-full max-w-sm lg:w-96">
            <h1 className="text-3xl text-center font-semibold">Reset password</h1>
            <div className="mt-4">
                {errorMessage && !successMessage && <ErrorAlert message={errorMessage} />}
                {successMessage && !errorMessage && <SuccessAlert message={successMessage} />}
                <Formik
                    initialValues={{
                        password: '',
                        confirmedPassword: ''
                    }}
                    validationSchema={ResetPasswordValidation}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setSubmitting(true);
                            doConfirmPasswordReset(oobCode, values.password);
                            if (errorMessage) setErrorMessage('');
                            setSuccessMessage('Password reset complete. You can now sign in with your new password');
                        } catch (error) {
                            if (successMessage) setSuccessMessage('');
                            if (error.code === 'auth/invalid-action-code') {
                                setErrorMessage('Invalid or expired code. Please request a new password reset link')
                            } else {
                                setErrorMessage('An error occurred. Please try again')
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, errors, touched, isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="password">
                                    New password
                                </label>
                                <Field
                                    className="formik-input"
                                    type="password"
                                    id="password"
                                    name="password"
                                />
                                {touched.password && errors.password && <div className="text-red-700">{errors.password}</div>}
                            </div>
                            <div>
                                <label htmlFor="confirmedPassword">
                                    Confirm new password
                                </label>
                                <Field
                                    className="formik-input"
                                    type="password"
                                    id="confirmedPassword"
                                    name="confirmedPassword"
                                />
                                {touched.confirmedPassword && errors.confirmedPassword && <div className="text-red-700">{errors.confirmedPassword}</div>}
                            </div>
                            <div className="mt-3">
                                <button type="submit" class="w-full text-center btn-primary" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Reset Password'}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}