import { Routes, Route } from "react-router-dom";
import SignupPage from "./pages/login/SignupPage";
import SignupSuccessPage from "./pages/login/SignupSuccessPage";
import LoginPage from "./pages/login/LoginPage";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage";
import AccountPage from "./pages/account/AccountPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import TermsPage from "./pages/legal/TermsPage";
import PrivacyPage from "./pages/legal/PrivacyPage";
import PlansPage from "./pages/plans/PlansPage";
import CheckoutPage from "./pages/plans/CheckoutPage";
import CheckoutSuccessPage from "./pages/plans/CheckoutSuccessPage";
import UserManagementPage from "./pages/login/UserManagementPage";
import CompetitorPersonalitySurveyPage from "./pages/account/CompetitorPersonalitySurveyPage";
import ProfilePage from "./pages/account/ProfilePage";
import StartPersonalitySurveyPage from "./pages/survey/competitor-personality/StartPersonalitySurveyPage";
import PersonalFulfillmentPage from "./pages/survey/competitor-personality/PersonalFulfillmentPage";
import LoveOfTheGamePage from "./pages/survey/competitor-personality/LoveOfTheGamePage";
import CommunityCamaraderiePage from "./pages/survey/competitor-personality/CommunityCamaraderiePage";
import PracticalApplicationPage from "./pages/survey/competitor-personality/PracticalApplicationPage";
import AchievingGreatnessPage from "./pages/survey/competitor-personality/AchievingGreatnessPage";
import EndPersonalitySurveyPageWrapper from "./pages/survey/competitor-personality/EndPersonalitySurveyPage";
import CountdownPage from "./pages/login/CountdownPage";

function App() {
    return (
        <Routes>
            <Route path = "/" element={<CountdownPage />} />
            <Route path = "*" element={<CountdownPage />} />
            {/* <Route path = "/" element={<DashboardPage />} />
            <Route path = "/dashboard" element={<DashboardPage />} />
            <Route path = "*" element={<DashboardPage />} />
            <Route path = "/signup" element={<SignupPage />} />
            <Route path = "/signup-success" element={<SignupSuccessPage />} />
            <Route path = "/forgot-password" element={<ForgotPasswordPage />} />
            <Route path = "/usermgmt" element={<UserManagementPage />} />
            <Route path = "/login" element={<LoginPage />} />
            <Route path = "/account" element={<AccountPage />} />
            <Route path = "/competitor-personality" element={<CompetitorPersonalitySurveyPage />} />
            <Route path = "/terms" element={<TermsPage />} />
            <Route path = "/privacy" element={<PrivacyPage />} />
            <Route path = "/membership" element={<PlansPage />} />
            <Route path = "/membership/:plan/checkout" element={<CheckoutPage />} />
            <Route path = "/membership/:plan/checkout/success" element={<CheckoutSuccessPage />} />
            <Route path = "/members/:memberId" element={<ProfilePage />} />
            <Route path = "/personality-survey" element={<StartPersonalitySurveyPage />} />
            <Route path = "/personality-survey/personal-fulfillment" element={<PersonalFulfillmentPage />} />
            <Route path = "/personality-survey/love-of-the-game" element={<LoveOfTheGamePage />} />
            <Route path = "/personality-survey/community-camaraderie" element={<CommunityCamaraderiePage />} />
            <Route path = "/personality-survey/practical-application" element={<PracticalApplicationPage />} />
            <Route path = "/personality-survey/achieving-greatness" element={<AchievingGreatnessPage />} />
            <Route path = "/personality-survey/results" element={<EndPersonalitySurveyPageWrapper />} /> */}
            </Routes>
    );
};

export default App;